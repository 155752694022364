import React from "react";

// const Environment = "PRODUCTION";
const Environment = "TESTIG";
// const Environment = "DEVELOPMENT";
// const Environment = "LOCAL";

const URL =
  Environment === "PRODUCTION"
    ? "https://manageconstructs.com"
    : Environment === "TESTIG"
    ? "https://testing.manageconstructs.com"
    : Environment === "DEVELOPMENT"
    ? "https://development.manageconstructs.com"
    : Environment === "LOCAL"
    ? "http://localhost:4002"
    : "";

export default URL;
